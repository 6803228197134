<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-btn @click.prevent="$router.go(-1)" plain :disabled="isFetching">
          <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
          <div>上一頁</div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <div class="page-title">
              {{ $route.meta.title }}
            </div>
          </v-card-subtitle>
          <v-card-text class="my-5">
            <v-row>
              <v-col cols="12">
                <Datatable
                  :table-headers="studentTableHeaders"
                  :table-data="studentFormData"
                  :disableSort="true"
                  :disable-pagination="studentTableLoading"
                  :disableFiltering="true"
                  :itemTotal="-1"
                  :page-limit-options="[]"
                  :is-loading="studentTableLoading"
                  :removeShadow="true"
                  :showTopSlot="false"
                  :page="studentPage"
                  :pageLimit="studentLimit"
                  @options-update="onStudentTableChange"
                  no-data-text="沒有資料"
                >
                  <template v-slot:[`item.timetable_btn`]="{ item }">
                    <v-btn
                      color="primary"
                      small
                      class="px-4"
                      :disabled="isFetching"
                      text
                      @click="changeLesson(item.id)"
                    >
                      轉堂
                    </v-btn>
                    <v-btn
                      color="red"
                      small
                      class="px-4 text--white"
                      :disabled="isFetching"
                      text
                      @click="cancelApply(item.id)"
                    >
                      取消報名
                    </v-btn>
                  </template>
                </Datatable>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <div class="page-title">課堂出席列表</div>
          </v-card-subtitle>
          <v-card-text class="my-5">
            <v-row>
              <v-col md="5" cols="12">
                <form-control
                  :dense="true"
                  inputType="select"
                  v-model="filterLessonID"
                  label="課堂"
                  :disabled="isFetching"
                  :options="lessonFilterList"
                  @selectChange="getLessonFilter"
                />
              </v-col>
              <v-col md="5" cols="12">
                <form-control
                  :dense="true"
                  inputType="select"
                  v-model="filterUserID"
                  label="用戶"
                  :disabled="isFetching"
                  :options="userFilterList"
                  @selectChange="getStudentFilter"
                />
              </v-col>
              <v-col md="2" cols="12" class="d-flex align-center">
                <v-btn color="red" small class="px-4 text--white" :disabled="isFetching" text @click="clearFilter">
                  清除
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <Datatable
                  :table-headers="attendanceTableHeaders"
                  :table-data="formattedAttendanceList"
                  :disableSort="true"
                  :disable-pagination="attendanceTableLoading"
                  :disableFiltering="true"
                  :itemTotal="-1"
                  :page-limit-options="[]"
                  :is-loading="attendanceTableLoading"
                  :removeShadow="true"
                  :showTopSlot="false"
                  :page="attendancePage"
                  :pageLimit="attendanceLimit"
                  @options-update="onAttendanceTableChange"
                  no-data-text="沒有資料"
                >
                  <template v-slot:[`item.attendance`]="{ index }">
                    <v-select
                      color="indigo darken-4"
                      persistent-placeholder
                      :items="attendanceStatusList"
                      v-model="formattedAttendanceList[getRealAttendanceIndex(index)].attendance"
                      item-text="text"
                      item-value="value"
                      outlined
                      hide-details="auto"
                      :disabled="isFetching || cancelledIndexList.includes(getRealAttendanceIndex(index))"
                      no-data-text="沒有資料"
                      :dense="true"
                      @change="updateAttendanceStatus(index)"
                    />
                  </template>
                </Datatable>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  small
                  class="px-4 text--white"
                  :disabled="isFetching"
                  depressed
                  @click="saveAttendance"
                >
                  儲存
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiEyeOutline } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import { mdiChevronLeft } from '@mdi/js'
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'studentTimetableDetail',
  components: {
    FormControl,
    Datatable,
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
      },
    }
  },
  data() {
    return {
      mdiEyeOutline,

      studentTableLoading: false,
      studentTableHeaders: [
        { text: '報名日期', value: 'create_date' },
        { text: '用戶名稱', value: 'user_name' },
        { text: '用戶聯絡電話', value: 'phone' },
        { text: '人數', value: 'amount' },
        { text: '訂單狀態', value: 'order_status' },
        {
          text: '',
          value: 'timetable_btn',
          sortable: false,
          align: 'end',
        },
      ],
      studentFormData: [],
      studentPage: 1,
      studentLimit: 10,

      attendanceTableLoading: false,
      attendanceTableHeaders: [
        { text: '課堂', value: 'lesson_title' },
        { text: '日期', value: 'date' },
        { text: '時間', value: 'time' },
        { text: '用戶名稱', value: 'user_name' },
        { text: '用戶聯絡電話', value: 'phone' },
        { text: '狀態', value: 'attendance' },
      ],
      attendancePage: 1,
      attendanceLimit: 10,
      dbAttendanceList: [],
      formattedAttendanceList: [],
      saveFormatAttendanceList: [],

      updatedAttendanceList: [],

      cancelledIndexList: [],

      lessonList: [],

      filterUserID: '',
      filterLessonID: '',

      currentStudentID: -1,
      currentStudentIndex: -1,
      openDetailTable: false,
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    id: function () {
      return this.$route.params.id
    },

    orderStatusList: function () {
      return this.$orderStatusList
    },

    lessonFilterList: function () {
      return this.lessonList.map(obj => {
        return { text: `${obj.lesson_title} (${obj.date} ${obj.start_time}-${obj.end_time})`, value: obj.id }
      })
    },

    attendanceStatusList: function () {
      return this.$attendanceStatusList.map(obj => {
        return { ...obj, disabled: obj.value === 'cancelled' }
      })
    },

    userFilterList: function () {
      return this.studentFormData.map(obj => {
        return { text: `${obj.user_name} (${obj.phone})`, value: obj.user_id }
      })
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    async getCourseData() {
      let courseError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
          verify_token: this.getAdminToken(),
          get_lesson_only: true,
          course_mode: 'offline',
          status: 'active',
        }

        const result = await this.$XHR.api('cms_get_course_by_id', payload)
        this.$func.log('-----Get Course-----')
        this.$func.log(result)

        this.lessonList = result.lessons

        this.getTimetableOrder()
      } catch (error) {
        this.$func.log('-----Get Course Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          courseError = true
          message = '沒有權限'
        } else {
          courseError = true
        }

        this.setFetching(false)
      }

      if (courseError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'studentTimetable',
        })
      }
    },

    async getTimetableOrder() {
      this.studentTableLoading = true

      try {
        const payload = {
          verify_token: this.getAdminToken(),
          course_id: parseInt(this.id),
          get_user: true,
        }

        const result = await this.$XHR.api('cms_get_order', payload)
        this.$func.log('-----Get Timetable Order-----')
        this.$func.log(result)

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          const data = {
            id: resultData.id,
            create_date: resultData.create_date,
            user_name: '-',
            phone: '-',
            user_id: resultData.user_id,
            order_status: resultData.order_status,
            amount: resultData.amount,
          }

          if (this.$validate.DataValid(resultData.user_data)) {
            data.user_name = this.$validate.DataValid(resultData.user_data.user_name)
              ? resultData.user_data.user_name
              : '-'
            data.phone = this.$validate.DataValid(resultData.user_data.phone) ? resultData.user_data.phone : '-'
          }

          temp.push(data)
        }

        this.studentFormData = temp

        this.getAttendanceList()
      } catch (error) {
        this.$func.log('-----Get Timetable Order fail-----')
        this.$func.log(error)

        let msg = ''
        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }

        this.studentFormData = []
        this.dbAttendanceList = []
        this.formattedAttendanceList = []
        this.saveFormatAttendanceList = []
        this.updatedAttendanceList = []
        this.cancelledIndexList = []
        this.setFetching(false)
        this.$store.dispatch('toggleLoadingPage', false)
      } finally {
        this.studentTableLoading = false
      }
    },

    async getAttendanceList() {
      this.attendanceTableLoading = true

      try {
        const payload = {
          verify_token: this.getAdminToken(),
          course_id: parseInt(this.id),
          order_by: 'lesson_id asc, order_id desc',
        }

        const result = await this.$XHR.api('cms_get_attendance', payload)
        this.$func.log('-----Get Attendance List-----')
        this.$func.log(result)

        this.dbAttendanceList = result.data
      } catch (error) {
        this.$func.log('-----Get Attendance List fail-----')
        this.$func.log(error)

        let msg = ''
        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }

        this.dbAttendanceList = []
        this.formattedAttendanceList = []
        this.saveFormatAttendanceList = []
        this.updatedAttendanceList = []
        this.cancelledIndexList = []
      } finally {
        this.setFetching(false)
        this.attendanceTableLoading = false
        this.$store.dispatch('toggleLoadingPage', false)

        this.formatAttendanceList()
      }
    },

    formatAttendanceList() {
      this.formattedAttendanceList = []
      this.saveFormatAttendanceList = []
      this.updatedAttendanceList = []
      this.cancelledIndexList = []

      const temp = []

      for (let i = 0; i < this.lessonList.length; i++) {
        const data = {
          order_id: '',
          user_id: '',
          user_name: '',
          phone: '',
          course_id: this.lessonList[i].course_id,
          lesson_id: this.lessonList[i].id,
          lesson_title: this.lessonList[i].lesson_title,
          date: this.lessonList[i].date,
          time: `${this.lessonList[i].start_time}-${this.lessonList[i].end_time}`,
          attendance_id: '',
          attendance: 'pending',
        }

        for (let k = 0; k < this.studentFormData.length; k++) {
          data.order_id = this.studentFormData[k].id
          data.user_id = this.studentFormData[k].user_id
          data.user_name = this.studentFormData[k].user_name
          data.phone = this.studentFormData[k].phone
          data.attendance = 'pending'
          data.attendance_id = ''

          const filterAttendance = this.dbAttendanceList.filter(obj => {
            return (
              obj.order_id === parseInt(this.studentFormData[k].id) &&
              obj.user_id === parseInt(this.studentFormData[k].user_id) &&
              obj.lesson_id === parseInt(this.lessonList[i].id)
            )
          })

          if (filterAttendance.length > 0) {
            data.attendance = filterAttendance[0].attendance
            data.attendance_id = filterAttendance[0].id

            if (filterAttendance[0].attendance === 'cancelled') {
              this.cancelledIndexList.push(this.studentFormData.length * i + k)
            }
          }

          temp.push(JSON.parse(JSON.stringify(data)))
        }
      }

      this.formattedAttendanceList = temp
      this.saveFormatAttendanceList = JSON.parse(JSON.stringify(temp))
    },

    applyAttendanceFilter() {
      this.formattedAttendanceList = []

      if (this.$validate.DataValid(this.filterLessonID) || this.$validate.DataValid(this.filterUserID)) {
        this.formattedAttendanceList = JSON.parse(
          JSON.stringify(
            this.saveFormatAttendanceList.filter(obj => {
              let result = true
              if (this.$validate.DataValid(this.filterLessonID)) {
                result = result && obj.lesson_id === this.filterLessonID
              }

              if (this.$validate.DataValid(this.filterUserID)) {
                result = result && obj.user_id === this.filterUserID
              }
              return result
            }),
          ),
        )
      } else {
        this.formattedAttendanceList = JSON.parse(JSON.stringify(this.saveFormatAttendanceList))
      }
    },

    getLessonFilter(val) {
      this.attendancePage = 1
      this.filterLessonID = parseInt(val)
      this.applyAttendanceFilter()
    },

    getStudentFilter(val) {
      this.attendancePage = 1
      this.filterUserID = parseInt(val)
      this.applyAttendanceFilter()
    },

    onStudentTableChange(options) {
      this.studentPage = options.page
    },

    clearFilter() {
      this.attendancePage = 1
      this.filterLessonID = ''
      this.filterUserID = ''
      this.applyAttendanceFilter()
    },

    onAttendanceTableChange(options) {
      this.attendancePage = options.page
    },

    updateAttendanceStatus(index) {
      const realIndex = this.getRealAttendanceIndex(index)

      const updateData = this.formattedAttendanceList[realIndex]

      // search the index in saveFormatAttendanceList
      const searchIndex = this.saveFormatAttendanceList.findIndex(obj => {
        return (
          obj.lesson_id === updateData.lesson_id &&
          obj.user_id === updateData.user_id &&
          obj.order_id === updateData.order_id
        )
      })

      if (searchIndex !== -1) {
        this.saveFormatAttendanceList[searchIndex].attendance = updateData.attendance

        if (this.updatedAttendanceList.indexOf(searchIndex) === -1) {
          this.updatedAttendanceList.push(searchIndex)
        }
      }
    },

    getRealAttendanceIndex(index) {
      return (this.attendancePage - 1) * this.attendanceLimit + index
    },

    async saveAttendance() {
      if (!this.isFetching) {
        this.setFetching(true)

        if (this.updatedAttendanceList.length <= 0) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '沒有資料需要更新',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.setFetching(false)
          return
        }

        try {
          const newData = []
          const updatedData = []

          for (let i = 0; i < this.saveFormatAttendanceList.length; i++) {
            if (this.updatedAttendanceList.includes(i)) {
              const data = {
                order_id: parseInt(this.saveFormatAttendanceList[i].order_id),
                course_id: parseInt(this.saveFormatAttendanceList[i].course_id),
                lesson_id: parseInt(this.saveFormatAttendanceList[i].lesson_id),
                user_id: parseInt(this.saveFormatAttendanceList[i].user_id),
                attendance: this.saveFormatAttendanceList[i].attendance,
              }

              if (this.$validate.DataValid(this.saveFormatAttendanceList[i].attendance_id)) {
                data.id = parseInt(this.saveFormatAttendanceList[i].attendance_id)
                updatedData.push(JSON.parse(JSON.stringify(data)))
              } else {
                newData.push(JSON.parse(JSON.stringify(data)))
              }
            }
          }

          if (updatedData.length > 0) {
            const updateResult = await this.$XHR.api('cms_update_multiple_attendance', {
              verify_token: this.getAdminToken(),
              attendance_list: updatedData,
            })
            this.$func.log('-----Update Attendance-----')
            this.$func.log(updateResult)
          }

          if (newData.length > 0) {
            const addResult = await this.$XHR.api('cms_add_multiple_attendance', {
              verify_token: this.getAdminToken(),
              attendance_list: newData,
            })
            this.$func.log('-----Add Attendance-----')
            this.$func.log(addResult)
          }

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: null,
            refresh: true,
          })

          this.setShowDialog(true)
          this.setFetching(false)
        } catch (error) {
          this.$func.log('-----Save Attendance Fail-----')
          this.$func.log(error)

          let message = '儲存失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            message = '沒有權限'
          } else if (error.data === 'empty attendance list' || error.data === 'nothing is need to updated') {
            message = '沒有資料需要更新'
          } else if (error.data === 'order not found') {
            message = '找不到報名記錄'
          } else if (error.data === 'some order does not exists') {
            message = '部份報名記錄不存在'
          } else if (error.data === 'some order has been cancelled') {
            message = '部份報名記錄已被取消'
          } else if (error.data === 'lesson not found') {
            message = '找不到課堂'
          } else if (error.data === 'some lesson do not exists') {
            message = '部份課堂不存在'
          } else if (error.data === 'course id not match') {
            message = '課程與已報名課程不符合'
          } else if (error.data === 'user id not match') {
            message = '用戶沒有報名此課程'
          } else if (error.data === 'lesson does not relate to the course') {
            message = '課堂與課程不相關'
          }

          this.setFetching(false)
          this.submitSuccess = false
          this.setDialogMessage({
            message: message,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },

    changeLesson(id) {},
    cancelApply(id) {},
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.getCourseData()
    }
  },
}
</script>
